import React from 'react'
import useWidth from 'src/hooks/window/useWidth'
import { widths } from 'src/styles/breakpoints'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Circle, Button } from './style'
import ImageWebp from 'src/components/ImageWebp'

import comoAbrirMinhaContaPjJson from '../../assets/data/ComoAbrirMinhaConta.json'

type comoAbrirMinhaContaPjProps = {
  title: string;
  description: string;
  number: string;
}

const ComoAbrirMinhaContaPj = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth(300)
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-md-center justify-content-lg-between'>
          <div className='col-12 col-md-9 col-lg-6'>
            <ImageWebp
              altDescription='Imagem de um cartão de crédito Inter Empresas. Ao lado em destaque, a home do Super App Inter Empresas.'
              arrayNumbers={[ ]}
              arrayNumbersHeight={[ ]}
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/nova-pj-dobra7/image.webp'
            />
          </div>
          <div className='col-12 col-lg-6'>
            <h2 className='mb-4'>Como abrir minha conta digital PJ?</h2>
            {
              comoAbrirMinhaContaPjJson.map((item: comoAbrirMinhaContaPjProps) => (
                <div className='d-flex' key={item.title}>
                  <div className='mr-3'>
                    <Circle>{item.number}</Circle>
                  </div>
                  <div>
                    <h3 className='fs-20 lh-25 fs-lg-24 lh-lg-28 text-grayscale--500 fw-600'>{item.title}</h3>
                    <p className='fs-16 lh-19 text-grayscale--500 fw-400'>{item.description}</p>
                  </div>
                </div>
              ))
            }
            <Button
              href={width < widths.md ? 'https://inter-co-enterprise.onelink.me/q6ee/ent77lma' : 'https://conta-digital-pj.inter.co/login?/'}
              target='_blank'
              rel='noreferrer'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_07',
                  element_action: 'click button',
                  section_name: 'Como abrir minha conta digital PJ?',
                  element_name: 'Abrir Conta PJ Gratuita',
                  redirect_url: width < widths.md ? 'https://inter-co-enterprise.onelink.me/q6ee/ent77lma' : 'https://conta-digital-pj.inter.co/login?/',
                })
              }}
            >
              Abrir Conta PJ Gratuita
            </Button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ComoAbrirMinhaContaPj
